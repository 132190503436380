<template>
    <modal :show="show" v-if="isValid('Day Counter')">

        <div style="margin-bottom:0px" class="card" >
            <div class="card-body">
                <div class="col-lg-12">
                    <div class="tab-content" id="nav-tabContent">

                        <div class="modal-header">

                            <h5 class="modal-title" id="myModalLabel"> {{ $t('DayStarts.DayCounter') }}</h5>

                        </div>
                        <div class="card-body">
                            <div class="row" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'">
                                <h6 class="text-center">  {{UserName}}</h6>
                                <div class="form-group has-label col-sm-12 " v-if="isValid('CanViewTerminal') || isValid('MachineWisePrefix') || isValid('Terminal') || isValid('CanStartDay') ||  isValid('TouchInvoiceTemplate1')">
                                    <label class="text  font-weight-bolder"> {{ $t('DayStarts.Counter') }}:</label>
                                    <terminal-dropdown @input="getTerminalIds" :terminalType="terminalType" v-bind:values="counterList" :isAllSelected="false" />
                                </div>

                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('DayStarts.OpeningCash') }}:</label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="counterDetails.openingCash" type="number" disabled @click="$event.target.select()" />
                                </div>

                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('DayStarts.CashInHand') }}:</label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="counterDetails.cashInHand" type="number" disabled @click="$event.target.select()" />
                                </div>

                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('DayStarts.Bank') }}:</label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="counterDetails.bank" type="number" disabled @click="$event.target.select()" />
                                </div>
                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('DayStarts.Expense') }}:</label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" v-model="counterDetails.expense" type="number" disabled @click="$event.target.select()" />
                                </div>
                                <div class="form-group has-label col-sm-12 ">
                                    <label class="text  font-weight-bolder"> {{ $t('DayStarts.Total') }}: </label>
                                    <input class="form-control" v-bind:class="($i18n.locale == 'en' ||isLeftToRight()) ? 'text-left' : 'arabicLanguage'" :value="((counterDetails.openingCash + counterDetails.cashInHand)  - counterDetails.expense) | roundAmount" type="number" disabled @click="$event.target.select()" />
                                </div>

                            </div>
                        </div>


                        <div class="modal-footer justify-content-right">
                            <button type="button" class="btn btn-danger  mr-3 " v-on:click="$emit('close')">{{ $t('Close') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </modal>
    <acessdenied v-else :model=true></acessdenied>
</template>
<script>

    import 'vue-loading-overlay/dist/vue-loading.css';
    import clickMixin from '@/Mixins/clickMixin'
    export default {
        mixins: [clickMixin],
        props: ['show', 'type', 'counterCode', 'counterId','counterList'],
        data: function () {
            return {
                render: 0,
                time: 0,
                dateRander: 0,
                UserName: '',
                total: 0,
                grandTotal: 0,
                terminals: [],
                terminalType: 'CashCounter',
                counterDetails: {
                    bank : 0,
                    cashInHand  : 0,
                    counterCode : 0,
                    counterId : 0,
                    expense  : 0,
                    openingCash  : 0
                }
            }
        },
        methods:{
            getTerminalIds: function (value) {
                var ids = [];
                if (value != undefined && value != null && value != '' && value.length != 0) {
                    
                    this.terminals = value;
                    var root = this;

                    var token = '';
                    if (this.$session.exists()) {
                        token = localStorage.getItem('token');
                    }
                    if (value.length != 0) {
                        value.forEach(function (x) {
                            ids.push({ id: x.id, name: x.name });
                        })
                    }
                    


                    root.$https.post('/Product/DayCounterInformation', ids, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            root.counterDetails = {
                                bank: 0,
                                cashInHand: 0,
                                counterCode: 0,
                                counterId: 0,
                                expense: 0,
                                openingCash: 0
                            }
                            response.data.forEach(function (x) {
                                root.counterDetails.bank += x.bank;
                                root.counterDetails.cashInHand += x.cashInHand;
                                root.counterDetails.expense += x.expense;
                                root.counterDetails.openingCash += x.openingCash;
                            })

                            root.total = (root.counterDetails.cashInHand + root.counterDetails.openingCash) - root.counterDetails.expense;
                        });
                }
            },
        },
    }
</script>
